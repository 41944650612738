// ---------------------------------------------- modules import
import dayjs from "dayjs";
import { utils, writeFile } from "xlsx";

import { IConclusion_ColouredProgressiveMatrices } from "../../components/modules/report/conclusion/coloured_progressive_matrices/conclusion";
import { IConclusion_GayaBelajar } from "../../components/modules/report/conclusion/gaya_belajar/conclusion";
import { IConclusion_GayaBelajarSdKelas1Sampai3 } from "../../components/modules/report/conclusion/gaya_belajar_sd_kelas_1_sampai_3/conclusion";
import { IConclusion_GayaBelajarV2 } from "../../components/modules/report/conclusion/gaya_belajar_v2/conclusion";
import { IConclusion_KebiasaanBelajarV2 } from "../../components/modules/report/conclusion/kebiasaan_belajar_v2/conclusion";
import { IConclusion_KecerdasanMajemuk } from "../../components/modules/report/conclusion/kecerdasan_majemuk/conclusion";
import { IConclusion_KecerdasanMajemukV2 } from "../../components/modules/report/conclusion/kecerdasan_majemuk_v2/conclusion";
import { IConclusion_KesejahteraanPsikologiDanEmosionalV2 } from "../../components/modules/report/conclusion/kesejahteraan_psikologi_dan_emosional_v2/conclusion";
import { IConclusion_KondisiKeluargaV2 } from "../../components/modules/report/conclusion/kondisi_keluarga_v2/conclusion";
import { IConclusion_Personality } from "../../components/modules/report/conclusion/personality/conclusion";
import { IConclusion_PersonalityV2 } from "../../components/modules/report/conclusion/personality_v2/conclusion";
import { IConclusion_Riasec } from "../../components/modules/report/conclusion/riasec/conclusion";
import { IConclusion_RiasecV2 } from "../../components/modules/report/conclusion/riasec_v2/conclusion";
import { AssessmentIdEnum } from "../../enums/assessment_id";
import {
  AttributeIdEnum_AdversityQuotient,
  AttributeIdEnum_Disc,
  AttributeIdEnum_GayaBelajar,
  AttributeIdEnum_KebiasaanBelajar,
  AttributeIdEnum_KecerdasanMajemuk,
  AttributeIdEnum_KesejahteraanPsikologiDanEmosional,
  AttributeIdEnum_KondisiKeluarga,
  AttributeIdEnum_Personality,
  AttributeIdEnum_Riasec,
} from "../../enums/attribute_id";
import { ISubsetToken } from "../../models/subset_token";
import { IWorksheet } from "../../models/worksheet";
import { IConclusion_Spm } from "../../components/modules/report/conclusion/spm/conclusion";
import { IConclusion_AdversityQuotient } from "../../components/modules/report/conclusion/adversity_quotient/conclusion";
import { IConclusion_Disc } from "../../components/modules/report/conclusion/disc/conclusion";
import { IConclusion_Cfit } from "../../components/modules/report/conclusion/cfit/conclusion";

// import { categorizeDevLevel } from "utilities/dev_level/dev_level_utility";

// ---------------------------------------------- the hooks
export const useSheetContext = () => {
  // ---------------------------------------------- local functions
  const downloadReportsV1 = (
    subsetToken: ISubsetToken,
    worksheets: IWorksheet[]
  ) => {
    const workBook = utils.book_new();

    const info = [
      { a: `REPORT of ${subsetToken.name}` },
      { a: "" },
      {
        a: "Downloaded at",
        b: "",
        c: `: ${dayjs().format("DD MMMM YYYY")}`,
      },
      {
        a: "Version",
        b: "",
        c: `: V1`,
      },
      { a: "" },
      {
        a: "",
        b: "Profile",
        c: "",
        d: "Personality",
        e: "",
        f: "",
        g: "",
        h: "",
        i: "",
        j: "",
        k: "",
        l: "",
        m: "",
        n: "",
        o: "Gaya Belajar",
        p: "",
        q: "",
        r: "",
        s: "",
        t: "",
        u: "",
        v: "Kecerdasan Majemuk",
        w: "",
        x: "",
        y: "",
        z: "",
        aa: "",
        ab: "",
        ac: "",
        ad: "",
        ae: "",
        af: "",
        ag: "",
        ah: "Kesejahteraan Psikologi dan Emosional",
        ai: "",
        aj: "",
        ak: "",
        al: "",
        am: "",
        an: "",
        ao: "Kebiasaan Belajar",
        ap: "",
        aq: "",
        ar: "",
        as: "",
        at: "",
        au: "Kondisi Keluarga",
        av: "",
        aw: "",
        ax: "RIASEC",
      },
    ];

    const header = [
      {
        a: "No.",
        b: "Nama",
        c: "No Telp",
        d: "Extrovert (E)",
        e: "Introvert (I)",
        f: "Sensing (S)",
        g: "Intuition (N)",
        h: "Thinking (T)",
        i: "Feeling (F)",
        j: "Judging (J)",
        k: "Perceiving (P)",
        l: "Tipe Kepribadian",
        m: "Sangat Menonjol",
        n: "Butuh Pengembangan",
        o: "Visual",
        p: "Auditory",
        q: "Kinsethetic",
        r: "Profil Gaya Belajar",
        s: "Deskripsi",
        t: "Ciri-ciri",
        u: "Strategi Belajar",
        v: "Linguistik",
        w: "Matematis",
        x: "Spasial",
        y: "Kinestetik",
        z: "Musikal",
        aa: "Interpersonal",
        ab: "Intrapersonal",
        ac: "Naturalis",
        ad: "Profil Kecerdasan",
        ae: "Deskripsi",
        af: "Ciri-ciri Kecerdasan Majemuk",
        ag: "Saran Ekstrakurikuler",
        ah: "Mandiri",
        ai: "Penguasaan Lingkungan",
        aj: "Pertumbuhan Pribadi",
        ak: "Hubungan Positif dengan Orang Lain",
        al: "Tujuan Hidup",
        am: "Penerimaan diri",
        an: "Profil",
        ao: "Homework and assignments",
        ap: "Time allocation",
        aq: "Reading and note taking",
        ar: "Study period procedures",
        as: "Concentration",
        at: "Tipe Kebiasaan Belajar",
        au: "Cara orang tua mendidik",
        av: "Relasi antara anak dan anggota keluarga",
        aw: "Suasana Rumah",
        ax: "Realistic",
        ay: "Investigative",
        az: "Artistic",
        ba: "Social",
        bb: "Enterprising",
        bc: "Conventional",
        bd: "Tipe",
        be: "Deskripsi",
        bf: "Saran Jurusan di Kampus",
        bg: "Mata pelajaran yang mendukung",
      },
    ];

    const rows = worksheets.map((worksheet, index) => {
      const row = {} as { [key: string]: string | number };

      row["a"] = index + 1;

      const { profile, questionnaires } = worksheet;

      row["b"] = profile.name;
      row["c"] = profile.phone;

      questionnaires.forEach((questionnaire) => {
        const { assessment, result } = questionnaire;

        switch (assessment.id) {
          case AssessmentIdEnum.PERSONALITY: {
            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_Personality.E: {
                  row["d"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.I: {
                  row["e"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.S: {
                  row["f"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.N: {
                  row["g"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.T: {
                  row["h"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.F: {
                  row["i"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.J: {
                  row["j"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.P: {
                  row["k"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                default: {
                  return;
                }
              }
            });

            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_Personality;

            row["l"] = conclusion.name;
            row["m"] = conclusion.traits.join(" ");
            row["n"] = conclusion.suggestedGrowth.join(" ");

            break;
          }
          case AssessmentIdEnum.GAYA_BELAJAR: {
            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_GayaBelajar.VISUAL: {
                  row["o"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_GayaBelajar.AUDITORY: {
                  row["p"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_GayaBelajar.KINESTHETIC: {
                  row["q"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                default: {
                  return;
                }
              }
            });

            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_GayaBelajar;

            row["r"] = conclusion.name;
            row["s"] = conclusion.descriptions.join(" ");
            row["t"] = conclusion.traits.join(" ");
            row["u"] = conclusion.learningStrategies.join(" ");

            break;
          }
          case AssessmentIdEnum.KECERDASAN_MAJEMUK: {
            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_KecerdasanMajemuk.LINGUISTIC: {
                  row["v"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.LOGICAL_MATHEMATICAL: {
                  row["w"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.SPATIAL: {
                  row["x"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.BODILY_KINESTHETIC: {
                  row["y"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.MUSICAL: {
                  row["z"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.INTERPERSONAL: {
                  row["aa"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.INTRAPERSONAL: {
                  row["ab"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.NATURALISTIC: {
                  row["ac"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                default: {
                  return;
                }
              }
            });

            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_KecerdasanMajemuk;

            row["ad"] = conclusion.name;
            row["ae"] = conclusion.descriptions.join(" ");
            row["af"] = conclusion.traits.join(" ");
            row["ag"] = conclusion.suggestedExtracurriculars.join(", ");

            break;
          }
          case AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL: {
            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.MANDIRI: {
                  row["ah"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PENGUASAAN_LINGKUNGAN: {
                  row["ai"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PERTUMBUHAN_PRIBADI: {
                  row["aj"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.HUBUNGAN_POSITIF_DENGAN_ORANG_LAIN: {
                  row["ak"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.TUJUAN_HIDUP: {
                  row["al"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PENERIMAAN_DIRI: {
                  row["am"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                default: {
                  return;
                }
              }
            });

            row["an"] = [...result.attributes].sort((a, b) =>
              a.value > b.value ? -1 : 1
            )[0].name;

            break;
          }
          case AssessmentIdEnum.KEBIASAAN_BELAJAR: {
            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_KebiasaanBelajar.HOMEWORK_AND_ASSIGNMENTS: {
                  row["ao"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KebiasaanBelajar.TIME_ALLOCATION: {
                  row["ap"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KebiasaanBelajar.READING_AND_NOTE_TAKING: {
                  row["aq"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KebiasaanBelajar.STUDY_PERIOD_PROCEDURES: {
                  row["ar"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KebiasaanBelajar.CONCENTRATION: {
                  row["as"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                default: {
                  return;
                }
              }
            });

            row["at"] = [...result.attributes].sort((a, b) =>
              a.value > b.value ? -1 : 1
            )[0].name;

            break;
          }
          case AssessmentIdEnum.KONDISI_KELUARGA: {
            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_KondisiKeluarga.CARA_ORANG_TUA_MENDIDIK: {
                  row["au"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KondisiKeluarga.RELASI_ANTARA_ANAK_DAN_ANGGOTA_KELUARGA: {
                  row["av"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KondisiKeluarga.SUASANA_RUMAH: {
                  row["aw"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                default: {
                  return;
                }
              }
            });

            break;
          }
          case AssessmentIdEnum.RIASEC: {
            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_Riasec.R: {
                  row["ax"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Riasec.I: {
                  row["ay"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Riasec.A: {
                  row["az"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Riasec.S: {
                  row["ba"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Riasec.E: {
                  row["bb"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Riasec.C: {
                  row["bc"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                default: {
                  return;
                }
              }
            });

            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_Riasec[];

            const [dominantConclusion] = conclusion;

            row["bd"] = dominantConclusion.name;
            row["be"] = dominantConclusion.descriptions.join(" ");
            row["bf"] = dominantConclusion.suggestedMajors.join(", ");
            row["bg"] = dominantConclusion.supportingSubjects.join(", ");

            break;
          }
          default: {
            return;
          }
        }
      });

      return row;
    });

    const workSheet = utils.json_to_sheet([...info, ...header, ...rows], {
      skipHeader: true,
    });

    workSheet["!cols"] = [
      { wch: 4 },
      { wch: 40 },
      { wch: 15 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 15 },
      { wch: 30 },
      { wch: 30 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 20 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 15 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];

    utils.book_append_sheet(workBook, workSheet, `${subsetToken.name}`);

    writeFile(workBook, `Report of ${subsetToken.name}.xlsx`);
  };

  const downloadReportsV2 = (
    subsetToken: ISubsetToken,
    worksheets: IWorksheet[]
  ) => {
    const workBook = utils.book_new();

    const info: { [key: string]: string | number }[] = [];

    const header = [
      {
        a: "No.",
        b: "Periode",
        c: "Nama Sekolah",
        d: "Alamat",
        e: "Kecamatan",
        f: "Kelurahan",
        g: "Kota",
        h: "Provinsi",
        i: "Tingkat",
        j: "Kelas",
        k: "Nama",
        l: "Jenis Kelamin",
        m: "Extrovert (E)",
        n: "Introvert (I)",
        o: "Sensing (S)",
        p: "Intuition (N)",
        q: "Thinking (T)",
        r: "Feeling (F)",
        s: "Judging (J)",
        t: "Perceiving (P)",
        u: "Tipe Kepribadian",
        v: "Sangat Menonjol",
        w: "Butuh Pengembangan",
        x: "Linguistik",
        y: "Matematis",
        z: "Spasial",
        aa: "Kinestetik",
        ab: "Musikal",
        ac: "Interpersonal",
        ad: "Intrapersonal",
        ae: "Naturalis",
        af: "Profil Kecerdasan",
        ag: "Deskripsi Kecerdasan Majemuk",
        ah: "Ciri-ciri Kecerdasan Majemuk",
        ai: "Saran Ekstrakurikuler",
        aj: "Visual",
        ak: "Auditory",
        al: "Kinesthetic",
        am: "Profil Gaya Belajar",
        an: "Deskripsi Gaya Belajar",
        ao: "Ciri-ciri Gaya Belajar",
        ap: "Strategi Belajar",
        aq: "Mandiri",
        ar: "Kategori Mandiri",
        as: "Penguasaan Lingkungan",
        at: "Kategori Penguasaan Lingkungan",
        au: "Pertumbuhan Pribadi",
        av: "Kategori Pertumbuhan Pribadi",
        aw: "Hubungan Positif dengan Orang Lain",
        ax: "Kategori Hubungan Positif dengan Orang Lain",
        ay: "Tujuan Hidup",
        az: "Kategori Tujuan Hidup",
        ba: "Penerimaan diri",
        bb: "Kategori Penerimaan diri",
        bc: "Profil Kesejahteraan Psikologi dan Emosional",
        bd: "Pekerjaan Rumah dan Tugas",
        be: "Kategori Pekerjaan Rumah dan Tugas",
        bf: "Alokasi Waktu",
        bg: "Kategori Alokasi Waktu",
        bh: "Membaca dan Mencatat",
        bi: "Kategori Membaca dan Mencatat",
        bj: "Prosedur Periode Belajar",
        bk: "Kategori Prosedur Periode Belajar",
        bl: "Konsentrasi",
        bm: "Kategori Konsentrasi",
        bn: "Tipe Kebiasaan Belajar",
        bo: "Cara orang tua mendidik",
        bp: "Kategori Cara orang tua mendidik",
        bq: "Relasi antara anak dan anggota keluarga",
        br: "Kategori Relasi antara anak dan anggota keluarga",
        bs: "Suasana Rumah",
        bt: "Kategori Suasana Rumah",
        bu: "Realistic",
        bv: "Investigative",
        bw: "Artistic",
        bx: "Social",
        by: "Enterprising",
        bz: "Conventional",
        ca: "Tipe RIASEC",
        cb: "Deskripsi RIASEC",
        cc: "Saran Jurusan di Kampus",
        cd: "Mata pelajaran yang mendukung",
        ce: "Visual GB13",
        cf: "Auditory GB13",
        cg: "Kinesthetic GB13",
        ch: "Profil Gaya Belajar GB13",
        ci: "Deskripsi Gaya Belajar GB13",
        cj: "Ciri-ciri Gaya Belajar GB13",
        ck: "Strategi Belajar GB13",
        cl: "Total Skor CPM",
        cm: "Kategori Skor CPM",
        cn: "Kategori CPM",
        co: "Total Skor SPM",
        cp: "Kategori Skor SPM",
        cq: "Kategori SPM",
        cr: "Camper AQ",
        cs: "Climber AQ",
        ct: "Quitter AQ",
        cu: "Kategori AQ",
        cv: "K-D DISC", // LD
        cw: "K-I DISC", // LI
        cx: "K-S DISC", // LS
        cy: "K-C DISC", // LC
        cz: "P-D DISC", // MD
        da: "P-I DISC", // MI
        db: "P-S DISC", // MS
        dc: "P-C DISC", // MC
        dd: "Kategori DISC",
        de: "Taraf Kecerdasan CFIT",
        df: "Kategori CFIT",
        dg: "ID",
      },
    ];

    const rows = worksheets.map((worksheet, index) => {
      const row = {} as { [key: string]: string | number };

      row["a"] = index + 1;

      const { dateCreated, participantGroup, profile, questionnaires } =
        worksheet;

      row["b"] = dayjs(dateCreated).format("YYYY");
      row["c"] = participantGroup.name;
      row["d"] = participantGroup.description;
      row["e"] = "-";
      row["f"] = "-";
      row["g"] = participantGroup.location.regency.name;
      row["h"] = participantGroup.location.province.name;
      row["i"] = "-";
      row["j"] = "-";
      row["k"] = profile.name;
      row["l"] = profile.gender === "male" ? "Laki-laki" : "Perempuan";

      questionnaires.forEach((questionnaire) => {
        const { assessment, result } = questionnaire;

        switch (assessment.id) {
          case AssessmentIdEnum.PERSONALITY: {
            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_Personality.E: {
                  row["m"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.I: {
                  row["n"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.S: {
                  row["o"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.N: {
                  row["p"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.T: {
                  row["q"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.F: {
                  row["r"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.J: {
                  row["s"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Personality.P: {
                  row["t"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                default: {
                  return;
                }
              }
            });

            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_PersonalityV2;

            row["u"] = conclusion.name;
            row["v"] = conclusion.traits.join(" ");
            row["w"] = conclusion.suggestedGrowth.join(" ");

            break;
          }
          case AssessmentIdEnum.KECERDASAN_MAJEMUK: {
            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_KecerdasanMajemuk.LINGUISTIC: {
                  row["x"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.LOGICAL_MATHEMATICAL: {
                  row["y"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.SPATIAL: {
                  row["z"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.BODILY_KINESTHETIC: {
                  row["aa"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.MUSICAL: {
                  row["ab"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.INTERPERSONAL: {
                  row["ac"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.INTRAPERSONAL: {
                  row["ad"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_KecerdasanMajemuk.NATURALISTIC: {
                  row["ae"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                default: {
                  return;
                }
              }
            });

            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_KecerdasanMajemukV2[];

            row["af"] = conclusion[0].name;
            row["ag"] = conclusion[0].descriptions.join(" ");
            row["ah"] = conclusion[0].traits.join(" ");
            row["ai"] = "-";

            break;
          }
          case AssessmentIdEnum.GAYA_BELAJAR: {
            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_GayaBelajar.VISUAL: {
                  row["aj"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_GayaBelajar.AUDITORY: {
                  row["ak"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_GayaBelajar.KINESTHETIC: {
                  row["al"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                default: {
                  return;
                }
              }
            });

            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_GayaBelajarV2[];

            row["am"] = conclusion[0].name;
            row["an"] = conclusion[0].descriptions.join(" ");
            row["ao"] = conclusion[0].traits.join(" ");
            row["ap"] = conclusion[0].learningStrategies.join(" ");

            break;
          }
          case AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL: {
            const conclusions = JSON.parse(
              result.conclusion
            ) as IConclusion_KesejahteraanPsikologiDanEmosionalV2[];

            conclusions.forEach((conclusion) => {
              switch (conclusion.attributeId) {
                case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.MANDIRI: {
                  row["aq"] = (conclusion.value * 100).toFixed(0);
                  row["ar"] = conclusion.devLevel;
                  break;
                }
                case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PENGUASAAN_LINGKUNGAN: {
                  row["as"] = (conclusion.value * 100).toFixed(0);
                  row["at"] = conclusion.devLevel;
                  break;
                }
                case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PERTUMBUHAN_PRIBADI: {
                  row["au"] = (conclusion.value * 100).toFixed(0);
                  row["av"] = conclusion.devLevel;
                  break;
                }
                case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.HUBUNGAN_POSITIF_DENGAN_ORANG_LAIN: {
                  row["aw"] = (conclusion.value * 100).toFixed(0);
                  row["ax"] = conclusion.devLevel;
                  break;
                }
                case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.TUJUAN_HIDUP: {
                  row["ay"] = (conclusion.value * 100).toFixed(0);
                  row["az"] = conclusion.devLevel;
                  break;
                }
                case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PENERIMAAN_DIRI: {
                  row["ba"] = (conclusion.value * 100).toFixed(0);
                  row["bb"] = conclusion.devLevel;
                  break;
                }
                default: {
                  return;
                }
              }
            });

            row["bc"] = [...result.attributes].sort((a, b) =>
              a.value > b.value ? -1 : 1
            )[0].name;

            break;
          }
          case AssessmentIdEnum.KEBIASAAN_BELAJAR: {
            const conclusions = JSON.parse(
              result.conclusion
            ) as IConclusion_KebiasaanBelajarV2[];

            conclusions.forEach((conclusion) => {
              switch (conclusion.attributeId) {
                case AttributeIdEnum_KebiasaanBelajar.HOMEWORK_AND_ASSIGNMENTS: {
                  row["bd"] = (conclusion.value * 100).toFixed(0);
                  row["be"] = conclusion.devLevel;
                  break;
                }
                case AttributeIdEnum_KebiasaanBelajar.TIME_ALLOCATION: {
                  row["bf"] = (conclusion.value * 100).toFixed(0);
                  row["bg"] = conclusion.devLevel;
                  break;
                }
                case AttributeIdEnum_KebiasaanBelajar.READING_AND_NOTE_TAKING: {
                  row["bh"] = (conclusion.value * 100).toFixed(0);
                  row["bi"] = conclusion.devLevel;
                  break;
                }
                case AttributeIdEnum_KebiasaanBelajar.STUDY_PERIOD_PROCEDURES: {
                  row["bj"] = (conclusion.value * 100).toFixed(0);
                  row["bk"] = conclusion.devLevel;
                  break;
                }
                case AttributeIdEnum_KebiasaanBelajar.CONCENTRATION: {
                  row["bl"] = (conclusion.value * 100).toFixed(0);
                  row["bm"] = conclusion.devLevel;
                  break;
                }
                default: {
                  return;
                }
              }
            });

            row["bn"] = [...result.attributes].sort((a, b) =>
              a.value > b.value ? -1 : 1
            )[0].name;

            break;
          }
          case AssessmentIdEnum.KONDISI_KELUARGA: {
            const conclusions = JSON.parse(
              result.conclusion
            ) as IConclusion_KondisiKeluargaV2[];

            conclusions.forEach((conclusion) => {
              switch (conclusion.attributeId) {
                case AttributeIdEnum_KondisiKeluarga.CARA_ORANG_TUA_MENDIDIK: {
                  row["bo"] = (conclusion.value * 100).toFixed(0);
                  row["bp"] = conclusion.devLevel;
                  break;
                }
                case AttributeIdEnum_KondisiKeluarga.RELASI_ANTARA_ANAK_DAN_ANGGOTA_KELUARGA: {
                  row["bq"] = (conclusion.value * 100).toFixed(0);
                  row["br"] = conclusion.devLevel;
                  break;
                }
                case AttributeIdEnum_KondisiKeluarga.SUASANA_RUMAH: {
                  row["bs"] = (conclusion.value * 100).toFixed(0);
                  row["bt"] = conclusion.devLevel;
                  break;
                }
                default: {
                  return;
                }
              }
            });

            break;
          }
          case AssessmentIdEnum.RIASEC: {
            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_Riasec.R: {
                  row["bu"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Riasec.I: {
                  row["bv"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Riasec.A: {
                  row["bw"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Riasec.S: {
                  row["bx"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Riasec.E: {
                  row["by"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_Riasec.C: {
                  row["bz"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                default: {
                  return;
                }
              }
            });

            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_RiasecV2[];

            const [dominantConclusion] = conclusion;

            row["ca"] = dominantConclusion.name;
            row["cb"] = dominantConclusion.descriptions.join(" ");
            row["cc"] = dominantConclusion.suggestedMajors.join(", ");
            row["cd"] = dominantConclusion.supportingSubjects.join(", ");

            break;
          }
          case AssessmentIdEnum.GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3: {
            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_GayaBelajar.VISUAL: {
                  row["ce"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_GayaBelajar.AUDITORY: {
                  row["cf"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                case AttributeIdEnum_GayaBelajar.KINESTHETIC: {
                  row["cg"] = (attribute.value * 100).toFixed(0);
                  break;
                }
                default: {
                  return;
                }
              }
            });

            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_GayaBelajarSdKelas1Sampai3[];

            row["ch"] = conclusion[0].name;
            row["ci"] = conclusion[0].descriptions.join(" ");
            row["cj"] = conclusion[0].traits.join(" ");
            row["ck"] = conclusion[0].learningStrategies.join(" ");

            break;
          }
          case AssessmentIdEnum.COLOURED_PROGRESSIVE_MATRICES: {
            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_ColouredProgressiveMatrices;

            row["cl"] = conclusion.totalScore;
            row["cm"] = conclusion.scoreCategory;
            row["cm"] = conclusion.category;

            break;
          }
          case AssessmentIdEnum.SPM: {
            const conclusion = JSON.parse(result.conclusion) as IConclusion_Spm;

            row["co"] = conclusion.totalScore;
            row["cp"] = conclusion.scoreCategory;
            row["cq"] = conclusion.category;

            break;
          }
          case AssessmentIdEnum.ADVERSITY_QUOTIENT: {
            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_AdversityQuotient;

            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_AdversityQuotient.CAMPER: {
                  row["cr"] = attribute.value;
                  break;
                }
                case AttributeIdEnum_AdversityQuotient.CLIMBER: {
                  row["cs"] = attribute.value;
                  break;
                }
                case AttributeIdEnum_AdversityQuotient.QUITTER: {
                  row["ct"] = attribute.value;
                  break;
                }
                default: {
                  return;
                }
              }
            });

            row["cu"] = conclusion.category;

            break;
          }
          case AssessmentIdEnum.DISC: {
            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_Disc;

            result.attributes.forEach((attribute) => {
              switch (attribute.id) {
                case AttributeIdEnum_Disc.L_DOMINANCE: {
                  row["cv"] = attribute.value;
                  break;
                }
                case AttributeIdEnum_Disc.L_INFLUENCE: {
                  row["cw"] = attribute.value;
                  break;
                }
                case AttributeIdEnum_Disc.L_STEADINESS: {
                  row["cx"] = attribute.value;
                  break;
                }
                case AttributeIdEnum_Disc.L_CONSCIENTIOUSNESS: {
                  row["cy"] = attribute.value;
                  break;
                }
                case AttributeIdEnum_Disc.M_DOMINANCE: {
                  row["cz"] = attribute.value;
                  break;
                }
                case AttributeIdEnum_Disc.M_INFLUENCE: {
                  row["da"] = attribute.value;
                  break;
                }
                case AttributeIdEnum_Disc.M_STEADINESS: {
                  row["db"] = attribute.value;
                  break;
                }
                case AttributeIdEnum_Disc.M_CONSCIENTIOUSNESS: {
                  row["dc"] = attribute.value;
                  break;
                }
                default: {
                  return;
                }
              }
            });

            row["dd"] = conclusion.category;

            break;
          }
          case AssessmentIdEnum.CFIT: {
            const conclusion = JSON.parse(
              result.conclusion
            ) as IConclusion_Cfit;

            row["de"] = conclusion.scoreCategory;
            row["df"] = conclusion.category;

            break;
          }
          default: {
            return;
          }
        }
      });

      row["dg"] = worksheet.id;

      return row;
    });

    const workSheet = utils.json_to_sheet([...info, ...header, ...rows], {
      skipHeader: true,
    });

    workSheet["!cols"] = [
      { wch: 4 }, // a: "No.",
      { wch: 8 }, // b: "Periode",
      { wch: 40 }, // c: "Nama Sekolah",
      { wch: 32 }, // d: "Alamat",
      { wch: 16 }, // e: "Kecamatan",
      { wch: 16 }, // f: "Kelurahan",
      { wch: 24 }, // g: "Kota",
      { wch: 24 }, // h: "Provinsi",
      { wch: 8 }, // i: "Tingkat",
      { wch: 8 }, // j: "Kelas",
      { wch: 40 }, // k: "Nama",
      { wch: 12 }, // l: "Jenis Kelamin",
      { wch: 12 }, // m: "Extrovert (E)",
      { wch: 12 }, // n: "Introvert (I)",
      { wch: 12 }, // o: "Sensing (S)",
      { wch: 12 }, // p: "Intuition (N)",
      { wch: 12 }, // q: "Thinking (T)",
      { wch: 12 }, // r: "Feeling (F)",
      { wch: 12 }, // s: "Judging (J)",
      { wch: 12 }, // t: "Perceiving (P)",
      { wch: 16 }, // u: "Tipe Kepribadian",
      { wch: 40 }, // v: "Sangat Menonjol",
      { wch: 40 }, // w: "Butuh Pengembangan",
      { wch: 12 }, // x: "Linguistik",
      { wch: 12 }, // y: "Matematis",
      { wch: 12 }, // z: "Spasial",
      { wch: 12 }, // aa: "Kinestetik",
      { wch: 12 }, // ab: "Musikal",
      { wch: 12 }, // ac: "Interpersonal",
      { wch: 12 }, // ad: "Intrapersonal",
      { wch: 12 }, // ae: "Naturalis",
      { wch: 16 }, // af: "Profil Kecerdasan",
      { wch: 40 }, // ag: "Deskripsi Kecerdasan Majemuk",
      { wch: 40 }, // ah: "Ciri-ciri Kecerdasan Majemuk",
      { wch: 40 }, // ai: "Saran Ekstrakurikuler",
      { wch: 12 }, // aj: "Visual",
      { wch: 12 }, // ak: "Auditory",
      { wch: 12 }, // al: "Kinesthetic",
      { wch: 12 }, // am: "Profil Gaya Belajar",
      { wch: 40 }, // an: "Deskripsi Gaya Belajar",
      { wch: 40 }, // ao: "Ciri-ciri Gaya Belajar",
      { wch: 40 }, // ap: "Strategi Belajar",
      { wch: 32 }, // aq: "Mandiri",
      { wch: 32 }, // ar: "Kategori Mandiri",
      { wch: 32 }, // as: "Penguasaan Lingkungan",
      { wch: 32 }, // at: "Kategori Penguasaan Lingkungan",
      { wch: 32 }, // au: "Pertumbuhan Pribadi",
      { wch: 32 }, // av: "Kategori Pertumbuhan Pribadi",
      { wch: 32 }, // aw: "Hubungan Positif dengan Orang Lain",
      { wch: 32 }, // ax: "Kategori Hubungan Positif dengan Orang Lain",
      { wch: 32 }, // ay: "Tujuan Hidup",
      { wch: 32 }, // az: "Kategori Tujuan Hidup",
      { wch: 32 }, // ba: "Penerimaan diri",
      { wch: 32 }, // bb: "Kategori Penerimaan diri",
      { wch: 32 }, // bc: "Profil Kesejahteraan Psikologi dan Emosional",
      { wch: 32 }, // bd: "Pekerjaan Rumah dan Tugas",
      { wch: 32 }, // be: "Kategori Pekerjaan Rumah dan Tugas",
      { wch: 32 }, // bf: "Alokasi Waktu",
      { wch: 32 }, // bg: "Kategori Alokasi Waktu",
      { wch: 32 }, // bh: "Membaca dan Mencatat",
      { wch: 32 }, // bi: "Kategori Membaca dan Mencatat",
      { wch: 32 }, // bj: "Prosedur Periode Belajar",
      { wch: 32 }, // bk: "Kategori Prosedur Periode Belajar",
      { wch: 32 }, // bl: "Konsentrasi",
      { wch: 32 }, // bm: "Kategori Konsentrasi",
      { wch: 32 }, // bn: "Tipe Kebiasaan Belajar",
      { wch: 32 }, // bo: "Cara orang tua mendidik",
      { wch: 32 }, // bp: "Kategori Cara orang tua mendidik",
      { wch: 32 }, // bq: "Relasi antara anak dan anggota keluarga",
      { wch: 32 }, // br: "Kategori Relasi antara anak dan anggota keluarga",
      { wch: 32 }, // bs: "Suasana Rumah",
      { wch: 32 }, // bt: "Kategori Suasana Rumah",
      { wch: 32 }, // bu: "Realistic",
      { wch: 32 }, // bv: "Investigative",
      { wch: 32 }, // bw: "Artistic",
      { wch: 32 }, // bx: "Social",
      { wch: 32 }, // by: "Enterprising",
      { wch: 32 }, // bz: "Conventional",
      { wch: 32 }, // ca: "Tipe RIASEC",
      { wch: 32 }, // cb: "Deskripsi RIASEC",
      { wch: 32 }, // cc: "Saran Jurusan di Kampus",
      { wch: 32 }, // cd: "Mata pelajaran yang mendukung",
    ];

    utils.book_append_sheet(workBook, workSheet, `${subsetToken.name}`);

    writeFile(workBook, `Report of ${subsetToken.name}.xlsx`);
  };

  // const downloadReportsV2 = (subsetToken, worksheets) => {
  //   const workBook = utils.book_new();

  //   const info = [];

  //   const header = [
  //     {
  //       a: "No.",
  //       b: "Nama", // Profile
  //       c: "No Telp",
  //       d: "Usia",
  //       e: "Jenis Kelamin",
  //       f: "Extrovert (E)", // Personality
  //       g: "Introvert (I)",
  //       h: "Sensing (S)",
  //       i: "Intuition (N)",
  //       j: "Thinking (T)",
  //       k: "Feeling (F)",
  //       l: "Judging (J)",
  //       m: "Perceiving (P)",
  //       n: "Tipe Kepribadian",
  //       o: "Karakter Kepribadian Yang Kuat",
  //       p: "Saran Pengembangan Kepribadian",
  //       q: "Rekomendasi Karir",
  //       r: "Visual", // Gaya Belajar
  //       s: "Auditory",
  //       t: "Kinsethetic",
  //       u: "Profil Gaya Belajar",
  //       v: "Deskripsi Profil Gaya Belajar",
  //       w: "Ciri-ciri Profil Gaya Belajar",
  //       x: "Strategi Belajar",
  //       y: "Linguistik", // Kecerdasan Majemuk
  //       z: "Matematis",
  //       aa: "Spasial",
  //       ab: "Kinestetik",
  //       ac: "Musikal",
  //       ad: "Interpersonal",
  //       ae: "Intrapersonal",
  //       af: "Naturalis",
  //       ag: "Profil Kecerdasan #1",
  //       ah: "Deskripsi Profil Kecerdasan #1",
  //       ai: "Ciri-ciri Kecerdasan Majemuk #1",
  //       aj: "Profil Kecerdasan #2",
  //       ak: "Deskripsi Profil Kecerdasan #2",
  //       al: "Ciri-ciri Kecerdasan Majemuk #2",
  //       am: "Profil Kecerdasan #3",
  //       an: "Deskripsi Profil Kecerdasan #3",
  //       ao: "Ciri-ciri Kecerdasan Majemuk #3",
  //       ap: "Mandiri", // Kesejahteraan Psikologi dan Emosional
  //       aq: "Kategori Mandiri",
  //       ar: "Penguasaan Lingkungan",
  //       as: "Kategori Penguasaan Lingkungan",
  //       at: "Pertumbuhan Pribadi",
  //       au: "Kategori Pertumbuhan Pribadi",
  //       av: "Hubungan Positif dengan Orang Lain",
  //       aw: "Kategori Hubungan Positif dengan Orang Lain",
  //       ax: "Tujuan Hidup",
  //       ay: "Kategori Tujuan Hidup",
  //       az: "Penerimaan diri",
  //       ba: "Kategori Penerimaan diri",
  //       bb: "Homework and assignments", // Kebiasaan Belajar
  //       bc: "Kategori Homework and assignments",
  //       bd: "Time allocation",
  //       be: "Kategori Time allocation",
  //       bf: "Reading and note taking",
  //       bg: "Kategori Reading and note taking",
  //       bh: "Study period procedures",
  //       bi: "Kategori Study period procedures",
  //       bj: "Concentration",
  //       bk: "Kategori Concentration",
  //       bl: "Cara orang tua mendidik", // Kondisi Keluarga
  //       bm: "Kategori Cara orang tua mendidik",
  //       bn: "Relasi antara anak dan anggota keluarga",
  //       bo: "Kategori Relasi antara anak dan anggota keluarga",
  //       bp: "Suasana Rumah",
  //       bq: "Kategori Suasana Rumah",
  //       br: "Realistic", // RIASEC
  //       bs: "Investigative",
  //       bt: "Artistic",
  //       bu: "Social",
  //       bv: "Enterprising",
  //       bw: "Conventional",
  //       bx: "Profil RIASEC",
  //       by: "Deskripsi Profil RIASEC",
  //       bz: "Saran Jurusan di Kampus",
  //       ca: "Mata pelajaran yang mendukung",
  //     },
  //   ];

  //   const rows = worksheets.map((worksheet, index) => {
  //     const row = {};

  //     row["a"] = index + 1;

  //     const { dateCreated, profile, questionnaires } = worksheet;

  //     row["b"] = profile.name;
  //     row["c"] = profile.phone;
  //     row["d"] = dayjs(dateCreated).diff(dayjs(profile.birthdate), "year");
  //     row["e"] = profile.gender;

  //     questionnaires.forEach((questionnaire) => {
  //       const { assessment, result } = questionnaire;

  //       switch (assessment.id) {
  //         case AssessmentIdEnum.PERSONALITY: {
  //           result.attributes.forEach((attribute) => {
  //             switch (attribute.id) {
  //               case AttributeIdEnum_Personality.E: {
  //                 row["f"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_Personality.I: {
  //                 row["g"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_Personality.S: {
  //                 row["h"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_Personality.N: {
  //                 row["i"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_Personality.T: {
  //                 row["j"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_Personality.F: {
  //                 row["k"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_Personality.J: {
  //                 row["l"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_Personality.P: {
  //                 row["m"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               default: {
  //                 return;
  //               }
  //             }
  //           });

  //           const conclusion = JSON.parse(result.conclusion);

  //           row["n"] = conclusion.name;
  //           row["o"] = conclusion.traits.join(". ");
  //           row["p"] = conclusion.suggestedGrowth.join(". ");
  //           row["q"] = conclusion.careerRecommendations.join(", ");

  //           break;
  //         }
  //         case AssessmentIdEnum.GAYA_BELAJAR: {
  //           result.attributes.forEach((attribute) => {
  //             switch (attribute.id) {
  //               case AttributeIdEnum_GayaBelajar.VISUAL: {
  //                 row["r"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_GayaBelajar.AUDITORY: {
  //                 row["s"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_GayaBelajar.KINESTHETIC: {
  //                 row["t"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               default: {
  //                 return;
  //               }
  //             }
  //           });

  //           const conclusion = JSON.parse(result.conclusion);

  //           row["u"] = conclusion[0].name;
  //           row["v"] = conclusion[0].descriptions.join(" ");
  //           row["w"] = conclusion[0].traits.join(" ");
  //           row["x"] = conclusion[0].learningStrategies.join(" ");

  //           break;
  //         }
  //         case AssessmentIdEnum.KECERDASAN_MAJEMUK: {
  //           result.attributes.forEach((attribute) => {
  //             switch (attribute.id) {
  //               case AttributeIdEnum_KecerdasanMajemuk.LINGUISTIC: {
  //                 row["y"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_KecerdasanMajemuk.LOGICAL_MATHEMATICAL: {
  //                 row["z"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_KecerdasanMajemuk.SPATIAL: {
  //                 row["aa"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_KecerdasanMajemuk.BODILY_KINESTHETIC: {
  //                 row["ab"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_KecerdasanMajemuk.MUSICAL: {
  //                 row["ac"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_KecerdasanMajemuk.INTERPERSONAL: {
  //                 row["ad"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_KecerdasanMajemuk.INTRAPERSONAL: {
  //                 row["ae"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_KecerdasanMajemuk.NATURALISTIC: {
  //                 row["af"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               default: {
  //                 return;
  //               }
  //             }
  //           });

  //           const conclusion = JSON.parse(result.conclusion);

  //           row["ag"] = conclusion[0].name;
  //           row["ah"] = conclusion[0].descriptions.join(" ");
  //           row["ai"] = conclusion[0].traits.join(" ");
  //           row["aj"] = conclusion[1].name;
  //           row["ak"] = conclusion[1].descriptions.join(" ");
  //           row["al"] = conclusion[1].traits.join(" ");
  //           row["am"] = conclusion[2].name;
  //           row["an"] = conclusion[2].descriptions.join(" ");
  //           row["ao"] = conclusion[2].traits.join(" ");

  //           break;
  //         }
  //         case AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL: {
  //           result.attributes.forEach((attribute) => {
  //             switch (attribute.id) {
  //               case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.MANDIRI: {
  //                 row["ap"] = (attribute.value * 100).toFixed(0);
  //                 row["aq"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PENGUASAAN_LINGKUNGAN: {
  //                 row["ar"] = (attribute.value * 100).toFixed(0);
  //                 row["as"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PERTUMBUHAN_PRIBADI: {
  //                 row["at"] = (attribute.value * 100).toFixed(0);
  //                 row["au"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.HUBUNGAN_POSITIF_DENGAN_ORANG_LAIN: {
  //                 row["av"] = (attribute.value * 100).toFixed(0);
  //                 row["aw"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.TUJUAN_HIDUP: {
  //                 row["ax"] = (attribute.value * 100).toFixed(0);
  //                 row["ay"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               case AttributeIdEnum_KesejahteraanPsikologiDanEmosional.PENERIMAAN_DIRI: {
  //                 row["az"] = (attribute.value * 100).toFixed(0);
  //                 row["ba"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               default: {
  //                 return;
  //               }
  //             }
  //           });

  //           break;
  //         }
  //         case AssessmentIdEnum.KEBIASAAN_BELAJAR: {
  //           result.attributes.forEach((attribute) => {
  //             switch (attribute.id) {
  //               case AttributeIdEnum_KebiasaanBelajar.HOMEWORK_AND_ASSIGNMENTS: {
  //                 row["bb"] = (attribute.value * 100).toFixed(0);
  //                 row["bc"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               case AttributeIdEnum_KebiasaanBelajar.TIME_ALLOCATION: {
  //                 row["bd"] = (attribute.value * 100).toFixed(0);
  //                 row["be"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               case AttributeIdEnum_KebiasaanBelajar.READING_AND_NOTE_TAKING: {
  //                 row["bf"] = (attribute.value * 100).toFixed(0);
  //                 row["bg"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               case AttributeIdEnum_KebiasaanBelajar.STUDY_PERIOD_PROCEDURES: {
  //                 row["bh"] = (attribute.value * 100).toFixed(0);
  //                 row["bi"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               case AttributeIdEnum_KebiasaanBelajar.CONCENTRATION: {
  //                 row["bj"] = (attribute.value * 100).toFixed(0);
  //                 row["bk"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               default: {
  //                 return;
  //               }
  //             }
  //           });

  //           break;
  //         }
  //         case AssessmentIdEnum.KONDISI_KELUARGA: {
  //           result.attributes.forEach((attribute) => {
  //             switch (attribute.id) {
  //               case AttributeIdEnum_KondisiKeluarga.CARA_ORANG_TUA_MENDIDIK: {
  //                 row["bl"] = (attribute.value * 100).toFixed(0);
  //                 row["bm"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               case AttributeIdEnum_KondisiKeluarga.RELASI_ANTARA_ANAK_DAN_ANGGOTA_KELUARGA: {
  //                 row["bn"] = (attribute.value * 100).toFixed(0);
  //                 row["bo"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               case AttributeIdEnum_KondisiKeluarga.SUASANA_RUMAH: {
  //                 row["bp"] = (attribute.value * 100).toFixed(0);
  //                 row["bq"] = categorizeDevLevel(assessment.id, attribute.id);
  //                 break;
  //               }
  //               default: {
  //                 return;
  //               }
  //             }
  //           });

  //           break;
  //         }
  //         case AssessmentIdEnum.RIASEC: {
  //           result.attributes.forEach((attribute) => {
  //             switch (attribute.id) {
  //               case AttributeIdEnum_Riasec.R: {
  //                 row["br"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_Riasec.I: {
  //                 row["bs"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_Riasec.A: {
  //                 row["bt"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_Riasec.S: {
  //                 row["bu"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_Riasec.E: {
  //                 row["bv"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               case AttributeIdEnum_Riasec.C: {
  //                 row["bw"] = (attribute.value * 100).toFixed(0);
  //                 break;
  //               }
  //               default: {
  //                 return;
  //               }
  //             }
  //           });

  //           const conclusion = JSON.parse(result.conclusion);

  //           const [dominantConclusion] = conclusion;

  //           row["bx"] = dominantConclusion.name;
  //           row["by"] = dominantConclusion.descriptions.join(" ");
  //           row["bz"] = dominantConclusion.suggestedMajors.join(", ");
  //           row["ca"] = dominantConclusion.supportingSubjects.join(", ");

  //           break;
  //         }
  //         default: {
  //           return;
  //         }
  //       }
  //     });

  //     return row;
  //   });

  //   const workSheet = utils.json_to_sheet([...info, ...header, ...rows], {
  //     skipHeader: true,
  //   });

  //   workSheet["!cols"] = [
  //     { wch: 4 }, // a
  //     { wch: 40 }, // b
  //     { wch: 10 }, // c
  //     { wch: 15 }, // d
  //     { wch: 15 }, // e
  //     { wch: 12 }, // f
  //     { wch: 12 }, // g
  //     { wch: 12 }, // h
  //     { wch: 12 }, // i
  //     { wch: 12 }, // j
  //     { wch: 12 }, // k
  //     { wch: 12 }, // l
  //     { wch: 12 }, // m
  //     { wch: 15 }, // n
  //     { wch: 30 }, // o
  //     { wch: 30 }, // p
  //     { wch: 30 }, // q
  //     { wch: 10 }, // r
  //     { wch: 10 }, // s
  //     { wch: 10 }, // t
  //     { wch: 20 }, // u
  //     { wch: 30 }, // v
  //     { wch: 30 }, // w
  //     { wch: 30 }, // x
  //     { wch: 10 }, // y
  //     { wch: 10 }, // z
  //     { wch: 10 }, // aa
  //     { wch: 10 }, // ab
  //     { wch: 10 }, // ac
  //     { wch: 10 }, // ad
  //     { wch: 10 }, // ae
  //     { wch: 10 }, // af
  //     { wch: 15 }, // ag
  //     { wch: 30 }, // ah
  //     { wch: 30 }, // ai
  //     { wch: 30 }, // aj
  //     { wch: 30 }, // ak
  //     { wch: 30 }, // al
  //     { wch: 30 }, // am
  //     { wch: 30 }, // an
  //     { wch: 30 }, // ao
  //     { wch: 30 }, // ao
  //     { wch: 30 }, // aq
  //     { wch: 30 }, // ar
  //     { wch: 30 }, // as
  //     { wch: 30 }, // at
  //     { wch: 30 }, // au
  //     { wch: 30 }, // av
  //     { wch: 30 }, // aw
  //     { wch: 30 }, // ax
  //     { wch: 30 }, // ay
  //     { wch: 30 }, // az
  //     { wch: 30 }, // ba
  //     { wch: 30 }, // bb
  //     { wch: 30 }, // bc
  //     { wch: 30 }, // bd
  //     { wch: 30 }, // be
  //     { wch: 30 }, // bf
  //     { wch: 30 }, // bg
  //     { wch: 30 }, // bh
  //     { wch: 30 }, // bi
  //     { wch: 30 }, // bj
  //     { wch: 30 }, // bk
  //     { wch: 30 }, // bl
  //     { wch: 30 }, // bm
  //     { wch: 30 }, // bn
  //     { wch: 30 }, // bo
  //     { wch: 30 }, // bp
  //     { wch: 30 }, // bq
  //     { wch: 30 }, // br
  //     { wch: 30 }, // bs
  //     { wch: 30 }, // bt
  //     { wch: 30 }, // bu
  //     { wch: 30 }, // bv
  //     { wch: 30 }, // bw
  //     { wch: 30 }, // bx
  //     { wch: 30 }, // by
  //     { wch: 30 }, // bz
  //     { wch: 30 }, // ca
  //   ];

  //   utils.book_append_sheet(workBook, workSheet, `${subsetToken.name}`);

  //   writeFile(workBook, `Report of ${subsetToken.name}.xlsx`);
  // };

  // ---------------------------------------------- handlers
  const handleDownloadReports = (
    subsetToken: ISubsetToken,
    worksheets: IWorksheet[]
  ) => {
    const allV1 = worksheets.every((worksheet) => !worksheet.version);
    const allV2 = worksheets.every((worksheet) => worksheet.version === "2");

    switch (true) {
      case allV1:
        downloadReportsV1(subsetToken, worksheets);
        break;
      case allV2:
        downloadReportsV2(subsetToken, worksheets);
        break;
      default:
        throw new Error(
          "Mixed versions or unsupported versions of worksheets are not allowed."
        );
    }
  };

  // ---------------------------------------------- return value
  return {
    onDownloadReports: handleDownloadReports,
  };
};
